export const CAMPAIGN_CREATE_SUCCESS = "CAMPAIGN_CREATE_SUCCESS";
export const CAMPAIGN_CREATE_REQUEST = "CAMPAIGN_CREATE_REQUEST";
export const CAMPAIGN_CREATE_FAILURE = "CAMPAIGN_CREATE_FAILURE";
export const CAMPAIGN_FETCHED_DATA_SUCCESS = "CAMPAIGN_FETCHED_DATA_SUCCESS";
export const CAMPAIGN_LIST_REQUEST = "CAMPAIGN_LIST_REQUEST";
export const CAMPAIGN_GET_FAILURE = "CAMPAIGN_GET_FAILURE";
export const CAMPAIGN_GET_DROPDOWN_LIST = "CAMPAIGN_GET_DROPDOWN_LIST";
export const CAMPAIGN_DELETE_REQUEST = "CAMPAIGN_DELETE_REQUEST";
export const CAMPAIGN_DELETE_SUCCESS = "CAMPAIGN_DELETE_SUCCESS";
export const CAMPAIGN_DELETE_FAILURE = "CAMPAIGN_DELETE_FAILURE";
export const CAMPAIGN_GETBYID_REQUEST = "CAMPAIGN_GETBYID_REQUEST";
export const CAMPAIGN_GETBYID_SUCCESS = "CAMPAIGN_GETBYID_SUCCESS";
export const CAMPAIGN_GETBYID_FAILURE = "CAMPAIGN_GETBYID_FAILURE";
export const CAMPAIGN_LOCATION_REQUEST = "CAMPAIGN_LOCATION_REQUEST";
export const CAMPAIGN_LOCATION_SUCCESS = "CAMPAIGN_LOCATION_SUCCESS";
export const CAMPAIGN_LOCATION_FAILURE = "CAMPAIGN_LOCATION_FAILURE";

export const COMPAIGN_SELECTED_IDS = "COMPAIGN_SELECTED_IDS";
