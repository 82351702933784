export const DISTRICT_FETCHED_DATA_SUCCESS = "DISTRICT_FETCHED_DATA_SUCCESS";
export const DISTRICT_LIST_REQUEST = "DISTRICT_LIST_REQUEST";
export const DISTRICT_GET_FAILURE = "DISTRICT_GET_FAILURE";
export const DISTRICT_GET_DROPDOWN_LIST = "DISTRICT_GET_DROPDOWN_LIST";

export const DISTRICT_DELETE_REQUEST = "DISTRICT_DELETE_REQUEST";
export const DISTRICT_DELETE_SUCCESS = "DISTRICT_DELETE_SUCCESS";
export const DISTRICT_DELETE_FAILURE = "DISTRICT_DELETE_FAILURE";

export const DISTRICT_CREATE_REQUEST = "DISTRICT_CREATE_REQUEST";
export const DISTRICT_CREATE_SUCCESS = "DISTRICT_CREATE_SUCCESS";
export const DISTRICT_CREATE_FAILURE = "DISTRICT_CREATE_FAILURE";

export const DISTRICT_UPDATE_REQUEST = "DISTRICT_UPDATE_REQUEST";
export const DISTRICT_UPDATE_SUCCESS = "DISTRICT_UPDATE_SUCCESS";
export const DISTRICT_UPDATE_FAILURE = "DISTRICT_UPDATE_FAILURE";

export const DISTRICT_SELECTED_IDS = "DISTRICT_SELECTED_IDS";
