import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts";
// import Dashboard from "src/views/dashboard";
import NotFoundView from "src/views/errors/NotFoundView";
import UserForm from "./views/user/create";
import UserList from "./views/user/index";
import UserView from "./views/user/view";
import Login from "./views/auth/Login";
import UserLocation from "./components/UserLocation";
import { State } from "./views/state";
import StateForm from "./views/state/create";
import StateView from "./views/state/view";
import { NgoPartner } from "./views/ngo_partner";
import NogPartnerForm from "./views/ngo_partner/create";
import NGOView from "./views/ngo_partner/view";
import { Volunteer } from "./views/volunteer";
import VolunteerForm from "./views/volunteer/create";
import VolunteerView from "./views/volunteer/view";
import District from "./views/district";
import DistrictForm from "./views/district/create";
import DistrictView from "./views/district/view";
import Block from "./views/block";
import BlockForm from "./views/block/create";
import BlockView from "./views/block/view";
import Domain from "./views/domain/index";
import DomainForm from "./views/domain/create";
import DomainView from "./views/domain/view";
import SubDomain from "./views/sub_domain/index";
import SubDomainForm from "./views/sub_domain/create";
import SubDomainView from "./views/sub_domain/view";
import Stream from "./views/streams/index";
import StreamForm from "./views/streams/create";
import StreamView from "./views/streams/view";
import CampaignForm from "./views/campaign/create";
import Campaign from "./views/campaign";
import CampaignView from "./views/campaign/view";
import Beneficiary from "./views/beneficiary";
import BeneficiaryForm from "./views/beneficiary/create";
import BeneficiaryView from "./views/beneficiary/view";
import BulkUpload from "./views/bulk_upload";
import Signup from "./views/auth/Signup";
import CampaignLocations from "./views/campaign/locations";
import ForgetPassword from "./views/auth/ForgetPassword";
import ResetPasswordSuccess from "./views/auth/ResetPasswordSuccess";
import Languages from "./views/languages";
import LanguagesView from "./views/languages/view";
import LanguagesForm from "./views/languages/create";
import BeneficiaryIntervention from "./views/beneficiary_intervention/index";
import BenInterventionView from "./views/beneficiary_intervention/view";
import CommunityIntervention from "./views/community_intervention/index";
import CommunityInterventionView from "./views/community_intervention/view";
import { Cluster } from './views/cluster/index'
import { ClusterForm } from './views/cluster/create'
import { Village } from "./views/village";
import { VillageForm } from "./views/village/create";
import { School } from "./views/school";
import { SchoolForm } from "./views/school/create";
import { BaselineEndline } from "./views/baseline_endline_aser";
import { BaselineEndlineSeel } from "./views/baseline_endline_seel";
import { Attendance } from "./views/attendance";
import { AttendanceView } from "./views/attendance/view";
import GapAssessmentOne from "./views/gap_assessement_module_one";
import { Anganwadi } from "./views/anganwadi/index";
import GapAssessmentTwo from "./views/gap_assessment_module_two";
import GapAssessmentTwoView from "./views/gap_assessment_module_two/view";
import GapAssessmentOneView from "./views/gap_assessement_module_one/view";
import { AnganwadiForm } from "./views/anganwadi/create";
import ChildAssessmentTool from "./views/child_assessment_tool";
import ChildAssessmentToolView from "./views/child_assessment_tool/view";
import MonitoringEvaluationStaffView from "./views/monitoring_evolution_staff/view";
import MonitoringEvaluationStaff from "./views/monitoring_evolution_staff";
import MonitoringEvaluationVolunteer from "./views/monitoring-evaluation-volunteer";
import MonitoringEvaluationVolunteerView from "./views/monitoring-evaluation-volunteer/view";
import Bala from "./views/bala";
import BalaView from "./views/bala/view";
import Assembly from "./views/assembly";
import AssemblyView from "./views/assembly/view";
import Library from "./views/library";
import LibraryView from "./views/library/view";
import BalSansad from "./views/bal_sansad";
import BalSansadView from "./views/bal_sansad/view";


const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      // { path: "dashboard", element: <Dashboard /> },
      { path: "dashboard", element: <State /> },
      { path: "user_management/list", element: <UserList /> },
      { path: "user_management/create", element: <UserForm /> },
      { path: "user_management/update/:id", element: <UserForm /> },
      { path: "user_management/view/:id", element: <UserView /> },

      { path: "user/location/:id", element: <UserLocation /> },
      { path: "state/list", element: <State /> },
      { path: "state/create", element: <StateForm /> },
      { path: "state/update/:id", element: <StateForm /> },
      { path: "state/view/:id", element: <StateView /> },

      { path: "district/list", element: <District /> },
      { path: "district/create", element: <DistrictForm /> },
      { path: "district/update/:id", element: <DistrictForm /> },
      { path: "district/view/:id", element: <DistrictView /> },

      { path: "block/list", element: <Block /> },
      { path: "block/create", element: <BlockForm /> },
      { path: "block/update/:id", element: <BlockForm /> },
      { path: "block/view/:id", element: <BlockView /> },

      { path: "domain/list", element: <Domain /> },
      { path: "domain/create", element: <DomainForm /> },
      { path: "domain/update/:id", element: <DomainForm /> },
      { path: "domain/view/:id", element: <DomainView /> },

      { path: "sub_domain/list", element: <SubDomain /> },
      { path: "sub_domain/create", element: <SubDomainForm /> },
      { path: "sub_domain/update/:id", element: <SubDomainForm /> },
      { path: "sub_domain/view/:id", element: <SubDomainView /> },

      { path: "stream/list", element: <Stream /> },
      { path: "stream/create", element: <StreamForm /> },
      { path: "stream/update/:id", element: <StreamForm /> },
      { path: "stream/view/:id", element: <StreamView /> },


      { path: "gramPanchayat/list", element: <Cluster /> },
      { path: "gramPanchayat/create", element: <ClusterForm /> },
      { path: "gramPanchayat/update/:id", element: <ClusterForm /> },

      { path: "village/list", element: <Village /> },
      { path: "village/create", element: <VillageForm /> },
      { path: "village/update/:id", element: <VillageForm /> },

      { path: "school/list", element: <School /> },
      { path: "school/create", element: <SchoolForm /> },
      { path: "school/update/:id", element: <SchoolForm /> },

      { path: "anganwadi/list", element: <Anganwadi /> },
      { path: "anganwadi/create", element: <AnganwadiForm /> },
      { path: "anganwadi/update/:id", element: <AnganwadiForm /> },
      // { path: "languages/update/:id", element: <LanguagesForm /> },
      // { path: "languages/view/:id", element: <LanguagesView /> },


      { path: "ngo/list", element: <NgoPartner /> },
      { path: "ngo/create", element: <NogPartnerForm /> },
      { path: "ngo/update/:id", element: <NogPartnerForm /> },
      { path: "ngo/view/:id", element: <NGOView /> },

      { path: "volunteer/list", element: <Volunteer /> },
      { path: "volunteer/create", element: <VolunteerForm /> },
      { path: "volunteer/update/:id", element: <VolunteerForm /> },
      { path: "volunteer/view/:id", element: <VolunteerView /> },

      { path: "campaign/list", element: <Campaign /> },
      { path: "campaign/create", element: <CampaignForm /> },
      { path: "campaign/update/:id", element: <CampaignForm /> },
      { path: "campaign/view/:id", element: <CampaignView /> },
      { path: "campaign/locations/:id", element: <CampaignLocations /> },

      { path: "beneficiary/list", element: <Beneficiary /> },
      { path: "beneficiary/create", element: <BeneficiaryForm /> },
      { path: "beneficiary/update/:id", element: <BeneficiaryForm /> },
      { path: "beneficiary/view/:id", element: <BeneficiaryView /> },

      { path: "languages/list", element: <Languages /> },
      { path: "languages/create", element: <LanguagesForm /> },
      { path: "languages/update/:id", element: <LanguagesForm /> },
      { path: "languages/view/:id", element: <LanguagesView /> },
      {
        path: "beneficiary_intervention/list",
        element: <BeneficiaryIntervention />,
      },
      {
        path: "beneficiary_intervention/view/:id",
        element: <BenInterventionView />,
      },
      {
        path: "community_intervention/list",
        element: <CommunityIntervention />,
      },
      {
        path: "community_intervention/view/:id",
        element: <CommunityInterventionView />,
      },
      {
        path: "baseline-endline/list",
        element: <BaselineEndline />,
      },
      {
        path: "baseline-endline-seel/list",
        element: <BaselineEndlineSeel />,
      },
      {
        path: "attendance/list",
        element: <Attendance />,
      },
      {
        path: "attendance/view/:id",
        element: <AttendanceView />,
      },
      { path: "gap-assessment-one/list", element: <GapAssessmentOne /> },
      { path: "gap-assessment-one/view/:id", element: <GapAssessmentOneView /> },

      { path: "gap-assessment-two/list", element: <GapAssessmentTwo /> },
      { path: "gap-assessment-two/view/:id", element: <GapAssessmentTwoView /> },

      { path: "child-assessment-tool/list", element: <ChildAssessmentTool /> },
      { path: "child-assessment-tool/view/:id", element: <ChildAssessmentToolView /> },

      { path: "monitoring-evaluation-staff/list", element: <MonitoringEvaluationStaff /> },
      { path: "monitoring-evaluation-staff/view/:id", element: <MonitoringEvaluationStaffView /> },

      { path: "monitoring-evaluation-volunteer/list", element: <MonitoringEvaluationVolunteer /> },
      { path: "monitoring-evaluation-volunteer/view/:id", element: <MonitoringEvaluationVolunteerView /> },

      { path: "bala/list", element: <Bala /> },
      { path: "bala/view/:id", element: <BalaView /> },

      { path: "assembly/list", element: <Assembly /> },
      { path: "assembly/view/:id", element: <AssemblyView /> },

      { path: "library/list", element: <Library /> },
      { path: "library/view/:id", element: <LibraryView /> },

      { path: "bal-sansad/list", element: <BalSansad /> },
      { path: "bal-sansad/view/:id", element: <BalSansadView /> },

      { path: "bulk_upload", element: <BulkUpload /> },
      { path: "*", element: <Navigate to="/404" /> },

    ],
  },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forgetpassword", element: <ForgetPassword /> },
  { path: "/forgetpassword/:token", element: <ResetPasswordSuccess /> },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/app/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];
export default routes;
