export const USERLEVEL_LIST_REQUEST = "USERLEVEL_LIST_REQUEST";
export const USERLEVEL_LIST_SUCCESS = "USERLEVEL_LIST_SUCCESS";
export const USERLEVEL_LIST_FAILURE = "USERLEVEL_LIST_FAILURE";

export const USERLEVEL_CREATE_REQUEST = "USERLEVEL_CREATE_REQUEST";
export const USERLEVEL_CREATE_SUCCESS = "USERLEVEL_CREATE_SUCCESS";
export const USERLEVEL_CREATE_FAILURE = "USERLEVEL_CREATE_FAILURE";

export const USERLEVEL_UPDATE_REQUEST = "USERLEVEL_UPDATE_REQUEST";
export const USERLEVEL_UPDATE_SUCCESS = "USERLEVEL_UPDATE_SUCCESS";
export const USERLEVEL_UPDATE_FAILURE = "USERLEVEL_UPDATE_FAILURE";

export const USERLEVEL_DELETE_REQUEST = "USERLEVEL_DELETE_REQUEST";
export const USERLEVEL_DELETE_SUCCESS = "USERLEVEL_DELETE_SUCCESS";
export const USERLEVEL_DELETE_FAILURE = "USERLEVEL_DELETE_FAILURE";
