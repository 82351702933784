export const DOMAIN_LIST_REQUEST = "DOMAIN_LIST_REQUEST";
export const DOMAIN_DROPDOWN_SUCCESS = "DOMAIN_DROPDOWN_SUCCESS";
export const DOMAIN_LIST_SUCCESS = "DOMAIN_LIST_SUCCESS";
export const DOMAIN_LIST_FAILURE = "DOMAIN_LIST_FAILURE";

export const DOMAIN_CREATE_REQUEST = "DOMAIN_CREATE_REQUEST";
export const DOMAIN_CREATE_SUCCESS = "DOMAIN_CREATE_SUCCESS";
export const DOMAIN_CREATE_FAILURE = "DOMAIN_CREATE_FAILURE";

export const DOMAIN_UPDATE_REQUEST = "DOMAIN_UPDATE_REQUEST";
export const DOMAIN_UPDATE_SUCCESS = "DOMAIN_UPDATE_SUCCESS";
export const DOMAIN_UPDATE_FAILURE = "DOMAIN_UPDATE_FAILURE";

export const DOMAIN_DLELETE_REQUEST = "DOMAIN_DLELETE_REQUEST";
export const DOMAIN_DLELETE_SUCCESS = "DOMAIN_DLELETE_SUCCESS";
export const DOMAIN_DLELETE_FAILURE = "DOMAIN_DLELETE_FAILURE";

export const DOMAIN_SELECTED_IDS = "DOMAIN_SELECTED_IDS";
