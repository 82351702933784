export const STATE_CREATE_SUCCESS = "STATE_CREATE_SUCCESS";
export const STATE_CREATE_REQUEST = "STATE_CREATE_REQUEST";
export const STATE_CREATE_FAILURE = "STATE_CREATE_FAILURE";
export const STATE_FETCHED_DATA_SUCCESS = "STATE_FETCHED_DATA_SUCCESS";
export const STATE_LIST_REQUEST = "STATE_LIST_REQUEST";
export const STATE_GET_FAILURE = "STATE_GET_FAILURE";
export const STATE_GET_DROPDOWN_LIST = "STATE_GET_DROPDOWN_LIST";
export const STATE_DELETE_REQUEST = "STATE_DELETE_REQUEST";
export const STATE_DELETE_SUCCESS = "STATE_DELETE_SUCCESS";
export const STATE_DELETE_FAILURE = "STATE_DELETE_FAILURE";

export const STATE_SELECTED_IDS = "STATE_SELECTED_IDS";
