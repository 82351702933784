export const NGO_PARTNER_CREATE_SUCCESS = "NGO_PARTNER_CREATE_SUCCESS";
export const NGO_PARTNER_CREATE_REQUEST = "NGO_PARTNER_CREATE_REQUEST";
export const NGO_PARTNER_CREATE_FAILURE = "NGO_PARTNER_CREATE_FAILURE";
export const NGO_PARTNER_FETCHED_DATA_SUCCESS =
  "NGO_PARTNER_FETCHED_DATA_SUCCESS";
export const NGO_PARTNER_LIST_REQUEST = "NGO_PARTNER_LIST_REQUEST";
export const NGO_PARTNER_GET_FAILURE = "NGO_PARTNER_GET_FAILURE";
export const NGO_PARTNER_GET_DROPDOWN_LIST = "NGO_PARTNER_GET_DROPDOWN_LIST";
export const NGO_PARTNER_DELETE_REQUEST = "NGO_PARTNER_DELETE_REQUEST";
export const NGO_PARTNER_DELETE_SUCCESS = "NGO_PARTNER_DELETE_SUCCESS";
export const NGO_PARTNER_DELETE_FAILURE = "NGO_PARTNER_DELETE_FAILURE";
export const NGO_BULK_UPLOAD_REQUEST = "NGO_BULK_UPLOAD_REQUEST";
export const NGO_BULK_UPLOAD_SUCCESS = "NGO_BULK_UPLOAD_SUCCESS";
export const NGO_BULK_UPLOAD_FAILURE = "NGO_BULK_UPLOAD_FAILURE";

export const NGO_SELECTED_IDS = "NGO_SELECTED_IDS";
