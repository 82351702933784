export const LANGUAGES_CREATE_SUCCESS = "LANGUAGES_CREATE_SUCCESS";
export const LANGUAGES_CREATE_REQUEST = "LANGUAGES_CREATE_REQUEST";
export const LANGUAGES_CREATE_FAILURE = "LANGUAGES_CREATE_FAILURE";
export const LANGUAGES_FETCHED_DATA_SUCCESS = "LANGUAGES_FETCHED_DATA_SUCCESS";
export const LANGUAGES_GET_REQUEST = "LANGUAGES_GET_REQUEST";
export const LANGUAGES_GET_FAILURE = "LANGUAGES_GET_FAILURE";
export const LANGUAGES_GET_DROPDOWN_LIST = "LANGUAGES_GET_DROPDOWN_LIST";
export const LANGUAGES_DELETE_REQUEST = "LANGUAGES_DELETE_REQUEST";
export const LANGUAGES_DELETE_SUCCESS = "LANGUAGES_DELETE_SUCCESS";
export const LANGUAGES_DELETE_FAILURE = "LANGUAGES_DELETE_FAILURE";

export const GET_COMPAIGN_LANGUAGES_REQUEST = "GET_COMPAIGN_LANGUAGES_REQUEST";
export const GET_COMPAIGN_LANGUAGES_SUCCESS = "GET_COMPAIGN_LANGUAGES_SUCCESS";
export const GET_COMPAIGN_LANGUAGES_FAILURE = "GET_COMPAIGN_LANGUAGES_FAILURE";
export const CREATE_COMPAIGN_LANGUAGES_REQUEST =
  "CREATE_COMPAIGN_LANGUAGES_REQUEST";
export const CREATE_COMPAIGN_LANGUAGES_SUCCESS =
  "CREATE_COMPAIGN_LANGUAGES_SUCCESS";
export const CREATE_COMPAIGN_LANGUAGES_FAILURE =
  "CREATE_COMPAIGN_LANGUAGES_FAILURE";

export const LANGUAGES_SELECTED_IDS = "LANGUAGES_SELECTED_IDS";
