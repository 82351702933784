export const VOLUNTEER_CREATE_SUCCESS = "VOLUNTEER_CREATE_SUCCESS";
export const VOLUNTEER_CREATE_REQUEST = "VOLUNTEER_CREATE_REQUEST";
export const VOLUNTEER_CREATE_FAILURE = "VOLUNTEER_CREATE_FAILURE";
export const VOLUNTEER_FETCHED_DATA_SUCCESS = "VOLUNTEER_FETCHED_DATA_SUCCESS";
export const VOLUNTEER_LIST_REQUEST = "VOLUNTEER_LIST_REQUEST";
export const VOLUNTEER_GET_FAILURE = "VOLUNTEER_GET_FAILURE";
export const VOLUNTEER_GET_DROPDOWN_LIST = "VOLUNTEER_GET_DROPDOWN_LIST";
export const VOLUNTEER_DELETE_REQUEST = "VOLUNTEER_DELETE_REQUEST";
export const VOLUNTEER_DELETE_SUCCESS = "VOLUNTEER_DELETE_SUCCESS";
export const VOLUNTEER_DELETE_FAILURE = "VOLUNTEER_DELETE_FAILURE";
export const VOLUNTEER_BULK_UPLOAD_REQUEST = "VOLUNTEER_BULK_UPLOAD_REQUEST";
export const VOLUNTEER_BULK_UPLOAD_SUCCESS = "VOLUNTEER_BULK_UPLOAD_SUCCESS";
export const VOLUNTEER_BULK_UPLOAD_FAILURE = "VOLUNTEER_BULK_UPLOAD_FAILURE";

export const VOLUNTEER_SELECTED_IDS = "VOLUNTEER_SELECTED_IDS";
