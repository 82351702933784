import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useLocation } from 'react-router';
import ViewData from 'src/components/Form/View';
import Page from 'src/components/Page';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(10),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const BalSansadView = () => {
    const classes = useStyles();
    const location = useLocation();

    let objTable = {
        tableConfig: [
            {
                "key": `school.name`,
                "title": "School",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.district.state.name",
                "title": "State",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.district.name",
                "title": "District",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.name",
                "title": "Block",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.name",
                "title": "Gram Panchayat",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.name",
                "title": "Village",
                "type": "string",
                "visible": true
            },
            {
                "key": "nonExistence",
                "title": "Non Existence",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_functioning_bala",
                "title": `"HM understands the key (bottleneck) challenge for the functioning of Bal Sansad"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup",
                "title": "Set up",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_understand_problem",
                "title": `"HM begins conversation with children around this so children build the capacity of understanding problems and recognize the need of a governance system for the same."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_builds_revamp",
                "title": `"HM   builds communication  with teachers to revamp the process"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_election_stud",
                "title": `"HM agrees to conduct fresh elections and build the necessary environment for student participation"
                Is micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "initialSuccess",
                "title": "Initial Success",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_election_school",
                "title": `"HM conducts fresh election in school"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_prepares_camp",
                "title": `"HM and teachers prepare students for campaigning"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "students_add_school",
                "title": `"Students identify issues that need to be addressed in the school
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "Camp_builds_account",
                "title": `"Campaign/ Momentum around Bal Sansad as drivers of change is generated so that children build the confidence to presenting their plans to groups and asking right questions for accountability."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_students_share_status",
                "title": `"HM creates a schedule for elected representatives to regularly meet with student body to identify problems and share the status of their work"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_process_function",
                "title": `"HM commits to setup a process for the smooth functioning of Bal Sansad"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity",
                "title": "Regularity",
                "type": "heading",
                "visible": true
            },
            {
                "key": "rep_meet_balsansad",
                "title": `"Students representatives meet regularly for 3 months  with active participation of all students along with teachers is encouraged in Bal Sansad meetings."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "child_prog_goals",
                "title": `"Children collaborate with each other to solve problems and review their progress against set goals for atleast 3 months"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "Bal_process_3",
                "title": `"Bal Sansad follows the process setup for its functioning for atleast 3 months"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "Hm_address_3_balsansad",
                "title": `"HM takes steps to address atleast one issue a month for atleast 3 months as raised by Bal Sansad"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance",
                "title": "Governance",
                "type": "heading",
                "visible": true
            },
            {
                "key": "Hm_student_review_plan",
                "title": `"HM supports students and teachers to organize review of their plans"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_solve_coach",
                "title": `"HM assesses students' leadership/ problem solving skills and provides coaching/ mentorship"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_election_process",
                "title": `"HM ensure fresh elections every year with a robust process"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability",
                "title": "Sustainability",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_bal_election",
                "title": `"HM plans for Bal Sansad at the beginning of the year initiating fresh elections every year with a robust process."
                Is micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "balsansad_shares_smc",
                "title": `"HM/ Bal Sansad shares progress reports with SMC semi annually"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "smc_owner_balsansad",
                "title": `"SMC/ Community takes active ownership and supports activities of the Bal Sansad"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },

        ],
    };


    let objView = Object.assign(objTable, { data: location?.state });
    return (
        <Page className={classes.root} href="/app/bal-sansad/list" title="Bal Sansad View">
            {location?.state && (<ViewData objView={objView} />)}
        </Page>
    )
}

export default BalSansadView;