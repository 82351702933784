import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useLocation } from 'react-router';
import ViewData from 'src/components/Form/View';
import Page from 'src/components/Page';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(10),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const AssemblyView = () => {
    const classes = useStyles();
    const location = useLocation();

    let objTable = {
        tableConfig: [
            {
                "key": `school.name`,
                "title": "School",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.district.state.name",
                "title": "State",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.district.name",
                "title": "District",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.name",
                "title": "Block",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.name",
                "title": "Gram Panchayat",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.name",
                "title": "Village",
                "type": "string",
                "visible": true
            },
            {
                "key": "nonExistence",
                "title": "Non Existence",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_key_chlg_assembly",
                "title": `"HM understands the key (bottleneck) challenge for the Assembly"
                Is micro milestone achieved? " 
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup",
                "title": "Set up",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_build_student",
                "title": `"HM builds communication with teachers to identify and involve more students in assembly"
                Is micro milestone achieved? "
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "asmbly_everyday_spc",
                "title": `"Assembly is conducted everyday in the morning  and becomes an inclusive and engaging space where different children get to lead it at regular intervals."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_teacher_child_act",
                "title": `"HM/Teachers prepares children with new child centered activities"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "initialSuccess",
                "title": "Initial Success",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_plan_std_asmbly",
                "title": `"HM creates a daily plan for the Assembly to encourage participation of students in Assembly"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_stakeholder_com",
                "title": `"HM brings stakeholders from the community to be part of the Assembly"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "camp_plan_spc_gen",
                "title": `"Campaign/ Momentum around planning assembly as a community space is generated"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "asmbly_platform_scul_act",
                "title": `"Assembly becomes a platform of building school community around school goals and vision, students, teachers and parents are acknowledged for their efforts and a variety of activities are used beyond what is prescribed."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_adult_plan_assembly",
                "title": `"HM identifies an Adult-in-charge to plan and execute the Assembly in the school"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_adult_create_act",
                "title": `"HM commits to working with the Adult in charge to create inclusive and meaningful activities"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity",
                "title": "Regularity",
                "type": "heading",
                "visible": true
            },
            {
                "key": "daily_plan_stud_3",
                "title": `"Adherence to the daily plan for the Assembly that encourages participation of students for atleast 3 months"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "community_parti_week",
                "title": `"Community participation in the assembly at least once a week for three months"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "child_act_every_asmbly",
                "title": `"Children, teachers and community have the freedom to come up with activities (that engage everyone and are meaningful) they would like to do/share with everyone in the assembly."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "adult_hm_act_3months",
                "title": `"Adult in Charge, works with the HM, and has created activities to be executed in the Assembly  for atleast 3 months"
                Is micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance",
                "title": "Governance",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_plans_supp_parti",
                "title": `"Adult-in-charge/ HM plans to engage  with those who need support to be able to participate"
                Is micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_teachers_parents",
                "title": `"Adult-in-charge/ HM supports new children, teachers and parents to adapt to the system."
                Is micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_goals_teachers",
                "title": `"HM sets goals with teachers for assembly"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability",
                "title": "Sustainability",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hm_supp_month",
                "title": `"Adult-in-charge/ HM organizes assembly with teachers and community support atleast once a month"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_progress_report",
                "title": `"Adult-in-charge/ HM shares progress reports with SMC quarterly"
                Is micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "smc_active_governance",
                "title": `"SMC/ Community takes active ownership and participates in governance and activities of the assembly"
                Is micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },

        ],
    };


    let objView = Object.assign(objTable, { data: location?.state });
    return (
        <Page className={classes.root} href="/app/assembly/list" title="Assembly View">
            {location?.state && (<ViewData objView={objView} />)}
        </Page>
    )
}

export default AssemblyView;