import { Card, CardContent, Grid, Avatar } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";

const ViewData = ({ objView }) => {
  const { tableConfig, data } = objView;
  const [rowCells, setRowCells] = useState([]);
  useEffect(() => {
    if (data?.length) {
      let dataM = data[0];
      let mainArr = [];
      for (let index = 0; index < tableConfig.length; index++) {
        let objTableConfig = tableConfig[index];
        if (dataM.hasOwnProperty(objTableConfig.key)) {
          if (objTableConfig?.type === "date") {
            mainArr.push(
              <div className="form-parent" key={index}>
                <div className="form-p">
                  {" "}
                  <span className=" form-p-title">{objTableConfig.title} : </span>
                  <span className="form-p-span">
                    {" "}
                    {dataM[objTableConfig.key] ? moment(dataM[objTableConfig.key]).format(objTableConfig?.date_format || "DD-MM-YYYY") : ""}
                  </span>
                </div>
                <br />
              </div>
            );
          } else if (objTableConfig?.type === "string") {
            mainArr.push(
              <div className="form-parent" key={index}>
                <div className="form-p">
                  <span className="form-p-title">{objTableConfig.title} : </span>
                  <span className="form-p-span">
                    {" "}
                    {dataM[objTableConfig.key]}
                  </span>
                </div>
                <br />
              </div>
            );
          } else if (objTableConfig?.type === "url") {
            mainArr.push(
              <div className="form-parent" key={index}>
                <div className="form-p">
                  <span className="margin-scale form-p-title">
                    {objTableConfig.title} :{" "}
                  </span>
                  {dataM[objTableConfig.key] && (
                    <a
                      className="form-p-span image-zoom"
                      href={dataM[objTableConfig.key]}
                      target="_blank"
                    >
                      {
                        <img
                          height={115}
                          width={115}
                          src={dataM[objTableConfig.key]}
                        />
                      }
                    </a>
                  )}
                </div>
                <br />
              </div>
            );
          } else if (objTableConfig?.type === "select") {
            mainArr.push(
              <div className="form-parent" key={index}>
                <div className="form-p">
                  <span className="form-p-title">{objTableConfig.title} : </span>
                  <span className="form-p-span">
                    {" "}
                    {objTableConfig?.optionArray?.find(elem => elem.id == dataM[objTableConfig.key])?.name}
                  </span>
                </div>
                <br />
              </div>
            );
          }
        } else {
          if (objTableConfig?.type === "heading") {
            mainArr.push(
              <div className="form-parent heading" key={index}>
                <span className="form-p heading">{objTableConfig.title}</span>
              </div>
            );
          }
        }
      }
      setRowCells(mainArr);
    }
  }, []);
  return (
    // <div className="viewWrapper">
    <div className="card">
      <Card>
        <CardContent>
          <Grid container>{rowCells}</Grid>
        </CardContent>
      </Card>
    </div>
  );
};
export default ViewData;
