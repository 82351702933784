export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SEND_EMAIL_REQUEST  = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS  = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE  = "SEND_EMAIL_FAILURE";
export const VERIFY_TOKEN_REQUEST  = "VERIFY_TOKEN_REQUEST";
export const VERIFY_TOKEN_SUCCESS  = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_FIALURE  = "VERIFY_TOKEN_FIALURE";
export const RESET_PASSWORD_REQUEST  = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS  = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE  = "RESET_PASSWORD_FAILURE";
export const LOGOUT = "LOGOUT";
