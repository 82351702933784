import axios from "axios";
import { FINANCIAL_YEAR_GET_FAILURE, FINANCIAL_YEAR_GET_REQUEST, FINANCIAL_YEAR_GET_SUCCESS } from "./actionType"
import { toast } from "react-toastify";
import { deleteData, getToken } from "src/utils/sessionStorage";

const baseUrl = process.env.REACT_APP_API_URL;

export const financialYearListAction = (payload) => (dispatch) => {
    dispatch({ type: FINANCIAL_YEAR_GET_REQUEST });

    axios({
        method: "get",
        url: `${baseUrl}financial_year/list`,
        headers: {
            "x-access-token": getToken(),
        },
    }).then((res) => {

        dispatch({ type: FINANCIAL_YEAR_GET_SUCCESS, payload: res?.data });
    }).catch((err) => {
        dispatch({ type: FINANCIAL_YEAR_GET_FAILURE });
        if (err.response !== undefined && err.response.status === 500) {
            toast.error("Internal Server Error");
        } else if (err.response !== undefined && err.response.status === 400) {
            toast.error(err.response.data.message);
        } else if (err?.response && err?.response?.status === 401) {
            deleteData('loginInfo')
            window.location.href = '/login';
        } else {
            toast.error(err.message);
        }
    });
}