import {
  Translate,
  QueuePlayNextOutlined,
  ReceiptOutlined,
  ExpandLess,
  ExpandMoreOutlined,
  PeopleAltOutlined,
  PostAddOutlined,
  FlagOutlined,
  DashboardOutlined,
  PlaylistAddCheck,
  FormatListBulleted,
  FileCopyOutlined,
  SchoolOutlinedIcon,
  SchoolOutlined
} from "@material-ui/icons";

export const navData = [
  // {
  //   icon: <DashboardOutlinedIcon />,
  //   title: "Dashboard",
  //   to: "/app/dashboard",
  //   id: 0,
  // },
  {
    icon: <DashboardOutlined />,
    openIcon: <ExpandMoreOutlined />,
    closedIcon: <ExpandLess />,
    title: "Master",
    to: "#",
    id: 0,
    subNav: [
      {
        title: "State",
        to: "/app/state/list",
        id: 0.1,
        isActive: false,
      },
      {
        title: "District",
        to: "/app/district/list",
        id: 0.2,
        isActive: false,
      },
      {
        title: "Block",
        to: "/app/block/list",
        id: 0.3,
        isActive: false,
      },
      {
        title: "Gram Panchayat",
        to: "/app/gramPanchayat/list",
        id: 0.4,
        isActive: false,
      },
      {
        title: "Village",
        to: "/app/village/list",
        id: 0.5,
        isActive: false,
      },
      {
        title: "School",
        to: "/app/school/list",
        id: 0.6,
        isActive: false,
      },
      {
        title: "Anganwadi",
        to: "/app/anganwadi/list",
        id: 0.7,
        isActive: false,
      },
      {
        title: "Domain",
        to: "/app/domain/list",
        id: 0.8,
        isActive: false,
      },
      {
        title: "Sub Domain",
        to: "/app/sub_domain/list",
        id: 0.9,
        isActive: false,
      },
      {
        title: "Stream",
        to: "/app/stream/list",
        id: 0.11,
        isActive: false,
      },
    ],
  },
  {
    icon: <QueuePlayNextOutlined />,
    openIcon: <ExpandMoreOutlined />,
    closedIcon: <ExpandLess />,
    title: "Campaigns",
    to: "#",
    id: 1,
    subNav: [
      {
        title: "Campaign",
        to: "/app/campaign/list",
        id: 1.1,
        isActive: false,
      },
      {
        icon: <FileCopyOutlined />,
        openIcon: <ExpandMoreOutlined />,
        closedIcon: <ExpandLess />,
        title: "SBA",
        to: "#",
        id: 1.2,
        subNav: [
          {
            title: "Community Intervention",
            to: "/app/community_intervention/list",
            id: 1.21,
            isActive: false,
          },
          {
            title: "Beneficiary Intervention",
            to: "/app/beneficiary_intervention/list",
            id: 1.22,
            isActive: false,
          },
        ]
      },
      {
        icon: <SchoolOutlined />,
        openIcon: <ExpandMoreOutlined />,
        closedIcon: <ExpandLess />,
        title: "Buniyaadin Shiksha Abhiyaan",
        to: "#",
        id: 1.3,
        subNav: [
          {
            title: "ASER Baseline Endline",
            to: "/app/baseline-endline/list",
            id: 1.31,
            isActive: false,
          },
          {
            title: "SEEL Baseline Endline",
            to: "/app/baseline-endline-seel/list",
            id: 1.32,
            isActive: false,
          },
          {
            title: "Attendance",
            to: "/app/attendance/list",
            id: 1.33,
            isActive: false,
          },
          {
            icon: <SchoolOutlined />,
            openIcon: <ExpandMoreOutlined />,
            closedIcon: <ExpandLess />,
            title: "School",
            to: "#",
            id: 1.34,
            subNav: [
              {
                title: "BaLA",
                to: "/app/bala/list",
                id: 1.341,
                isActive: false,
              },
              {
                title: "Assembly",
                to: "/app/assembly/list",
                id: 1.342,
                isActive: false,
              },
              {
                title: "Library",
                to: "/app/library/list",
                id: 1.343,
                isActive: false,
              },
              {
                title: "Bal Sansad",
                to: "/app/bal-sansad/list",
                id: 1.344,
                isActive: false,
              },
            ]
          },
        ]
      },
      {
        icon: <FormatListBulleted />,
        openIcon: <ExpandMoreOutlined />,
        closedIcon: <ExpandLess />,
        title: "Khushaal Bachpan Abhiyan",
        to: "#",
        id: 1.4,
        subNav: [
          {
            title: "Gap Assessment Module 1",
            to: "gap-assessment-one/list",
            id: 1.41,
            isActive: false,
          },
          {
            title: "Gap Assessment Module 2",
            to: "gap-assessment-two/list",
            id: 1.42,
            isActive: false,
          },
          {
            title: "Child Assessment Tool",
            to: "child-assessment-tool/list",
            id: 1.43,
            isActive: false,
          },
          {
            title: "M&E Form for Staff",
            to: "monitoring-evaluation-staff/list",
            id: 1.44,
            isActive: false,
          },
          {
            title: "M&E Form for Volunteer",
            to: "monitoring-evaluation-volunteer/list",
            id: 1.45,
            isActive: false,
          },
        ]
      },
    ],
  },
  {
    icon: <ReceiptOutlined />,
    title: "Beneficiary",
    to: "/app/beneficiary/list",
    id: 2,
    isActive: false,
  },
  {
    icon: <FlagOutlined />,
    title: "Volunteer",
    to: "/app/volunteer/list",
    id: 3,
    isActive: false,
  },

  {
    icon: <PostAddOutlined />,
    title: "NGO",
    to: "/app/ngo/list",
    id: 4,
    isActive: false,
  },

  {
    icon: <Translate />,
    title: "Languages",
    to: "/app/languages/list",
    id: 5,
    isActive: false,
  },

  {
    icon: <PeopleAltOutlined />,
    title: "User Management",
    to: "/app/user_management/list",
    id: 6,
    isActive: false,
  },
];
