import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useLocation } from 'react-router';
import ViewData from 'src/components/Form/View';
import Page from 'src/components/Page';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(10),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const LibraryView = () => {
    const classes = useStyles();
    const location = useLocation();

    let objTable = {
        tableConfig: [
            {
                "key": `school.name`,
                "title": "School",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.district.state.name",
                "title": "State",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.district.name",
                "title": "District",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.name",
                "title": "Block",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.name",
                "title": "Gram Panchayat",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.name",
                "title": "Village",
                "type": "string",
                "visible": true
            },
            {
                "key": "nonExistence",
                "title": "Non Existence",
                "type": "heading",
                "visible": true
            },
            {
                "key": "understand_chlng_library",
                "title": `"HM understands the key (bottleneck) challenge for the Library"
                Is micro milestone achieved?" 
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup",
                "title": "Set up",
                "type": "heading",
                "visible": true
            },
            {
                "key": "learning_beyond_books",
                "title": `"HM builds communication with teachers about the role of library an active space for students to access books/resource material for their learning beyond textbooks."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "book_register_maintained",
                "title": `"HM sets up a library space with appropriate books and a library register is maintained"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "teacher_engage_student",
                "title": `"HM/ Teachers engage students in library activities"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "initialSuccess",
                "title": "Initial Success",
                "type": "heading",
                "visible": true
            },
            {
                "key": "weekly_library_period",
                "title": `"HM sets up a dedicated weekly period of Library"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "activities_integrated",
                "title": `"HM and Teachers integrates lesson plans and classroom activities with Library"
                Is micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "plan_activities",
                "title": `"HM and Teachers plan activities around the library (E.g. Theater, Story telling)"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "various_purpose_camps",
                "title": `"Campaign/ Momentum around using library for various purposes has been built at a block level"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "adult_library_incharge",
                "title": `"HM identifies an Adult-in-charge to take ownership of the Library"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "book_assort_correctly",
                "title": `"HM and Teachers commit to the right assortment of books by genre, age and class"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity",
                "title": "Regularity",
                "type": "heading",
                "visible": true
            },
            {
                "key": "library_conducted_sucessfully",
                "title": `"Dedicated period of Library organised and conducted consistently for three months"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "activities_integrated_library",
                "title": `"All teachers (that fellow interacts with) in the school have integrated lesson plans and classroom activities with Library "
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "atleast_one_activity",
                "title": `"At least one activity a month around the library (E.g. Theater, Story telling) has been conducted over the last three months to increase the No. of children who access and issue books for reading at home and comprehension level of students go up."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "books_sorted_correctly",
                "title": `"The library books is stocked with the right assortment of books by genre, age and class"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "reqularity_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "reqularity_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "reqularity_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance",
                "title": "Governance",
                "type": "heading",
                "visible": true
            },
            {
                "key": "plan_library_use",
                "title": `"HM assesses  students on their reading and comprehension skills and uses the data to plan the use of library with teachers."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_library_data_use",
                "title": `"HM assesses the frequency of library usage for referencing and uses the data to plan the use of library"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_restocks_books",
                "title": `"HM restocks the library with appropraiate books."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_parents_read_home",
                "title": `"HM involves parents in supporting children to read at home."
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability",
                "title": "Sustainability",
                "type": "heading",
                "visible": true
            },
            {
                "key": "adult_events_twice_year",
                "title": `"Adult-in-charge/ HM organizes reading based events with teachers and community twice in a year"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hm_shares_progress_smc",
                "title": `"Adult-in-charge/ HM shares progress reports with SMC twice in a year"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "smc_act_owner_library",
                "title": `"SMC/ Community takes active ownership and participates in governance and activities of the library"
                Is micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },

        ],
    };


    let objView = Object.assign(objTable, { data: location?.state });
    return (
        <Page className={classes.root} href="/app/library/list" title="Library View">
            {location?.state && (<ViewData objView={objView} />)}
        </Page>
    )
}

export default LibraryView;