import RemoveIcon from "@material-ui/icons/Remove";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const GoTo = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [subNav, setSubnav] = useState(false);
  const [match, setMatch] = useState(false);

  const isMaster =
    item.subNav?.to === "/app/state/list" ||
    item.subNav?.to === "/app/district/list" ||
    item.subNav?.to === "/app/block/list" ||
    item.subNav?.to === "/app/domain/list" ||
    item.subNav?.to === "/app/sub_domain/list" ||
    item.subNav?.to === "/app/stream/list";

  const handleClick = (obj) => {
    if (obj.subNav) {
      setSubnav(!subNav);
    } else {
      navigate(obj.to, { replace: true });
    }
  };

  useEffect(() => {
    // setMatch(location.pathname === item.to);
    const titleFromPath = location.pathname.trim().split("/")[2];
    const titleFromPrps = item.title
      .split(" ")
      .join("_")
      .toLowerCase();

    // setMatch(
    //   location.pathname.indexOf(item.title.split(" ")[0].toLowerCase()) > -1
    // );
    setMatch(titleFromPath === titleFromPrps);
  }, [location.pathname, item.title]);

  return (
    <>
      <li
        className={`navItems ${(match || isMaster) && "active"} `}
        onClick={() => handleClick(item)}
      >
        <div className="navContentsWrapper">
          <div className="navContents">
            {item.icon}
            <span>{item.title}</span>
          </div>
          <div className="navSubNav">
            {item.subNav && subNav
              ? item.closedIcon
              : item.subNav
                ? item.openIcon
                : null}
          </div>
        </div>
      </li>

      {subNav && (
        <ul className="subNavItemsWrapper">
          {item.subNav.map((sub, index) => <>
            {"subNav" in sub ?
              <GoTo key={index} item={sub} />
              : <li
                key={sub.id + index}
                className={`subNavItems ${match && "active"} `}
                onClick={() => handleClick(sub)}
              >
                <RemoveIcon /> {sub.title}
              </li>
            }
          </>
          )}
        </ul>
      )}
    </>
  );
};

export default GoTo;
