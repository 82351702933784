export const SUBDOMAIN_LIST_REQUEST = "SUBDOMAIN_LIST_REQUEST";
export const SUBDOMAIN_DROPDOWN_SUCCESS = "SUBDOMAIN_DROPDOWN_SUCCESS";
export const SUBDOMAIN_LIST_SUCCESS = "SUBDOMAIN_LIST_SUCCESS";
export const SUBDOMAIN_LIST_FAILURE = "SUBDOMAIN_LIST_FAILURE";

export const SUBDOMAIN_CREATE_REQUEST = "SUBDOMAIN_CREATE_REQUEST";
export const SUBDOMAIN_CREATE_SUCCESS = "SUBDOMAIN_CREATE_SUCCESS";
export const SUBDOMAIN_CREATE_FAILURE = "SUBDOMAIN_CREATE_FAILURE";

export const SUBDOMAIN_UPDATE_REQUEST = "SUBDOMAIN_UPDATE_REQUEST";
export const SUBDOMAIN_UPDATE_SUCCESS = "SUBDOMAIN_UPDATE_SUCCESS";
export const SUBDOMAIN_UPDATE_FAILURE = "SUBDOMAIN_UPDATE_FAILURE";

export const SUBDOMAIN_DLELETE_REQUEST = "SUBDOMAIN_DLELETE_REQUEST";
export const SUBDOMAIN_DLELETE_SUCCESS = "SUBDOMAIN_DLELETE_SUCCESS";
export const SUBDOMAIN_DLELETE_FAILURE = "SUBDOMAIN_DLELETE_FAILURE";

export const SUBDOMAIN_SELECTED_IDS = "SUBDOMAIN_SELECTED_IDS";
