export const STREAM_LIST_REQUEST = "STREAM_LIST_REQUEST";
export const STREAM_DROPDOWN_SUCCESS = "STREAM_DROPDOWN_SUCCESS";
export const STREAM_LIST_SUCCESS = "STREAM_LIST_SUCCESS";
export const STREAM_LIST_FAILURE = "STREAM_LIST_FAILURE";

export const STREAM_CREATE_REQUEST = "STREAM_CREATE_REQUEST";
export const STREAM_CREATE_SUCCESS = "STREAM_CREATE_SUCCESS";
export const STREAM_CREATE_FAILURE = "STREAM_CREATE_FAILURE";

export const STREAM_UPDATE_REQUEST = "STREAM_UPDATE_REQUEST";
export const STREAM_UPDATE_SUCCESS = "STREAM_UPDATE_SUCCESS";
export const STREAM_UPDATE_FAILURE = "STREAM_UPDATE_FAILURE";

export const STREAM_DLELETE_REQUEST = "STREAM_DLELETE_REQUEST";
export const STREAM_DLELETE_SUCCESS = "STREAM_DLELETE_SUCCESS";
export const STREAM_DLELETE_FAILURE = "STREAM_DLELETE_FAILURE";

export const STREAM_SELECTED_IDS = "STREAM_SELECTED_IDS";
