// import { Avatar } from "@material-ui/core";
import "./index.css";
import NavItem from "./NavItem";
import { navData } from "./NavData";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navWrapper">
      <div className="navHead">
        <Link to="/app/dashboard">
          <img src="/image/adc-logo2.png" alt="logo" className="topBarLogo" />
        </Link>
      </div>

      <div className="navInner">
        <NavItem items={navData} />
      </div>
    </div>
  );
};

export default Navbar;
