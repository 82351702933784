import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import "./Top.css";
import CreateButton from "./CreateButton";
import { useNavigate } from "react-router-dom";
import { getToken } from "src/utils/sessionStorage";
import { filterUrl } from "src/utils/halper";
import Filter from "./Filter";

const baseUrl = process.env.REACT_APP_API_URL;

const Page = forwardRef((props, ref) => {
  const {
    children,
    title = "",
    href,
    btnTitle,
    downloadUrl,
    csvFilters,
    filterData,
  } = props;
  const navigate = useNavigate();

  const handleBackBtn = () => {
    navigate(href, { replace: true });
  };

  const handleCSVDownload = () => {
    delete csvFilters.limit;
    csvFilters['token'] = getToken();
    const url = `${baseUrl}${downloadUrl}${filterUrl(csvFilters)}`;
    window.location.href = url;
  };

  const handleBulkUpload = () => {
    navigate("/app/bulk_upload", {
      state: { title },
    });
  };

  return (
    <div className="layoutPage" ref={ref}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {btnTitle && title === "Dashboard" && (
        <div className="topBarWrapper">
          <h2>{title}</h2>
          <select className="topBarDropdown">
            <option value="1">FY 2020/21</option>
            <option value="2">FY 2021/22</option>
          </select>
        </div>
      )}

      {btnTitle &&
        (title === "Master/State" ||
          title === "Master/District" ||
          title === "Master/Block") ? (
        <div className="topBarWrapper">
          <h2>{title}</h2>
          <div className="topBarRightBtnsWrapper">
            <button className="csvBtn" onClick={handleCSVDownload}>
              CSV Download
            </button>
            {(title === "Volunteer" || title === "NGO") && (
              <button className="csvBtn" onClick={handleBulkUpload}>
                Bulk Upload
              </button>
            )}
          </div>
        </div>
      ) : (
        title !== "Dashboard" && (
          <div className={(btnTitle || downloadUrl) ? "topBarWrapper" : ""}>
            {downloadUrl && (<h2>{title}</h2>)}
            <div className="topBarRightBtnsWrapper">
              {
                downloadUrl &&
                (<button className="csvBtn" onClick={handleCSVDownload}>
                  CSV Download
                </button>)
              }
              {(title === "Volunteer" || title === "NGO") && (
                <button className="csvBtn" onClick={handleBulkUpload}>
                  Bulk Upload
                </button>
              )}
              {btnTitle && (<CreateButton title={btnTitle} href={href} />)}
            </div>
          </div>
        )
      )}

      {(!btnTitle && !downloadUrl) && (
        <div className="topBarWrapper">
          <div className="topBarInner">
            <div className="tobBarBackBtn" onClick={handleBackBtn}>
              <KeyboardBackspaceIcon />
            </div>
            <h2>{title}</h2>
          </div>
        </div>
      )}
      {filterData && <div className="filter">
        <Filter filterData={filterData} />
      </div>}
      <div>{children}</div>
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};
export default Page;
