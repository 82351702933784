import { FINANCIAL_YEAR_GET_FAILURE, FINANCIAL_YEAR_GET_REQUEST, FINANCIAL_YEAR_GET_SUCCESS } from './actionType'

const initialState = {
    isError: false,
    isLoading: false,
    data: [],
};
export const financialYearReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FINANCIAL_YEAR_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case FINANCIAL_YEAR_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            }
        case FINANCIAL_YEAR_GET_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}