import { useEffect, useState } from "react";
import { fetchApi } from "./halper";

const useGeographyFilterList = ({ filters, geoToIncludes }) => {
    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [blockList, setBlockList] = useState([]);
    const [clusterList, setClusterList] = useState([]);
    const [villageList, setVillageList] = useState([]);

    useEffect(() => {
        fetchApi("state/list", setStateList, { fetched: "All" })
    }, []);

    return [
        {
            name: "state_id", type: "select", title: "State ", optionsArray: stateList,
            onChange: (value) => fetchApi("district/list", setDistrictList, { state_id: value, fetched: "All" }),
            reset: ["district_id", "block_id", "cluster_id", "village_id"]
        },
        ...geoToIncludes.includes("district_id") ?
            [{
                name: "district_id", type: "select", title: "District", optionsArray: districtList,
                onChange: (value) => geoToIncludes.includes("block_id") && fetchApi("block/list", setBlockList, { state_id: filters.state_id, district_id: value, fetched: "All" }),
                reset: geoToIncludes.includes("block_id") ? ["block_id", "cluster_id", "village_id"] : []
            }] : [],
        ...geoToIncludes.includes("block_id") ?
            [{
                name: "block_id", type: "select", title: "Block", optionsArray: blockList,
                onChange: (value) => geoToIncludes.includes("cluster_id") && fetchApi("cluster/list", setClusterList, { state_id: filters.state_id, district_id: filters.district_id, block_id: value, fetched: "All" }),
                reset: geoToIncludes.includes("cluster_id") ? ["cluster_id", "village_id"] : []
            }] : [],
        ...geoToIncludes.includes("cluster_id") ?
            [{
                name: "cluster_id", type: "select", title: "Gram Panchayat", optionsArray: clusterList,
                onChange: (value) => geoToIncludes.includes("village_id") && fetchApi("village/list", setVillageList, { state_id: filters.state_id, district_id: filters.district_id, block_id: filters.block_id, cluster_id: value, fetched: "All" }),
                reset: geoToIncludes.includes("village_id") ? ["village_id"] : []
            }] : [],
        ...geoToIncludes.includes("village_id") ?
            [{ name: "village_id", type: "select", title: "Village", optionsArray: villageList, }] : [],
    ];
}

export default useGeographyFilterList;