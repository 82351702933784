export const BLOCK_FETCHED_DATA_SUCCESS = "BLOCK_FETCHED_DATA_SUCCESS";
export const BLOCK_LIST_REQUEST = "BLOCK_LIST_REQUEST";
export const BLOCK_GET_FAILURE = "BLOCK_GET_FAILURE";
export const BLOCK_GET_DROPDOWN_LIST = "BLOCK_GET_DROPDOWN_LIST";

export const CREATE_BLOCK_REQUEST = "CREATE_BLOCK_REQUEST";
export const CREATE_BLOCK_SUCCESS = "CREATE_BLOCK_SUCCESS";
export const CREATE_BLOCK_FAILURE = "CREATE_BLOCK_FAILURE";

export const UPDATE_BLOCK_REQUEST = "UPDATE_BLOCK_REQUEST";
export const UPDATE_BLOCK_SUCCESS = "UPDATE_BLOCK_SUCCESS";
export const UPDATE_BLOCK_FAILURE = "UPDATE_BLOCK_FAILURE";

export const DELETE_BLOCK_REQUEST = "DELETE_BLOCK_REQUEST";
export const DELETE_BLOCK_SUCCESS = "DELETE_BLOCK_SUCCESS";
export const DELETE_BLOCK_FAILURE = "DELETE_BLOCK_FAILURE";

export const BLOCK_SELECTED_IDS = "BLOCK_SELECTED_IDS";
