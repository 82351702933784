import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useLocation } from 'react-router';
import ViewData from 'src/components/Form/View';
import Page from 'src/components/Page';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(10),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const BalaView = () => {
    const classes = useStyles();
    const location = useLocation();

    let objTable = {
        tableConfig: [
            {
                "key": `school.name`,
                "title": "School",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.district.state.name",
                "title": "State",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.district.name",
                "title": "District",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.block.name",
                "title": "Block",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.cluster.name",
                "title": "Gram Panchayat",
                "type": "string",
                "visible": true
            },
            {
                "key": "school.village.name",
                "title": "Village",
                "type": "string",
                "visible": true
            },
            {
                "key": "nonExistence",
                "title": "Non Existence",
                "type": "heading",
                "visible": true
            },
            {
                "key": "understand_chlng_bala",
                "title": `"HM understands the key (bottleneck) challenge for the BaLA" 
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "ne_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup",
                "title": "Set up",
                "type": "heading",
                "visible": true
            },
            {
                "key": "identify_bala_activity",
                "title": `"HM  builds communication with teachers to identify appropriate BaLA activities for their classes so that children begin to relate concepts to spaces around them" 
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "identify_space_setup",
                "title": `"HM ensures Bala space is identified and setup"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "teacher_engages_act",
                "title": `"Teacher agree to use a few BaLA tools for 1 class; engages with the students using BaLA activities"  
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "setup_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "initialSuccess",
                "title": "Initial Success",
                "type": "heading",
                "visible": true
            },
            {
                "key": "activities_deploy_schl",
                "title": `"HM maps students scores to identify appropriate activities and gets them  developed across schools for different levels of students"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "class_specific_bala",
                "title": `"HM have Class-/Age-specific BALA Aids, which can be integrated into lesson plans"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "teacher_bala_lesson",
                "title": `"Teachers and HM use BaLA as part of their lesson plans"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "camp_bala_interact",
                "title": `"Campaign/ Momentum around BaLA as an interactive learning aid is generated so that children can explain concepts using space  around them"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "dedicated_child_exp",
                "title": `"HM creates a dedicated space for children to express themselves (E.g. Students Wall)"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "teachers_commit_bala",
                "title": `"HM and teachers commit to use BaLA for appropriate concepts"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "success_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity",
                "title": "Regularity",
                "type": "heading",
                "visible": true
            },
            {
                "key": "hM_bala_lesson",
                "title": `"Teachers and HM use BaLA as part of their lesson plans for 3 months"
                Is the micro milestone achieved? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "explain_concept_space",
                "title": `"Children can explain concepts using space  around them for atleast 3 months"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "space_to_child_express",
                "title": `"Dedicated space created for children to express themselves (E.g. Students Wall)  is updated atleast once every month for three months"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "regularity_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance",
                "title": "Governance",
                "type": "heading",
                "visible": true
            },
            {
                "key": "ability_exp_bala",
                "title": `"HM assesses students ability to explain concepts using BaLA"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "hM_supports_bala",
                "title": `"HM supports teachers to use BaLA effectively with students"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "implement_activities",
                "title": `"HM facilitates implementation of new activities"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "reviews_teacher_student",
                "title": `"HM conducts reviews with teachers and students/ Bal Sansad for updating BaLA"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "governance_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability",
                "title": "Sustainability",
                "type": "heading",
                "visible": true
            },
            {
                "key": "implement_at_beginning",
                "title": `"HM plans for BaLA implementation at the beginning of the year discussing appropriate lessons along with teachers."
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "report_smc_annually",
                "title": `"HM shares progress reports with SMC semi annually"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "smc_ownership_of_bala",
                "title": `"SMC/ Community takes active ownership and participates in governance and activities of the BaLA"
                Is the micro milestone achieved?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_is_fund_generated",
                "title": `Any fund has been generated at village/panchayat level?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_fund_source",
                "title": `What is the source of fund generation?`,
                "type": "string",
                "visible": true
            },
            {
                "key": "sustainability_fund_amount",
                "title": `How much fund has been generated at village/panchayat level? `,
                "type": "string",
                "visible": true
            },

        ],
    };


    let objView = Object.assign(objTable, { data: location?.state });
    return (
        <Page className={classes.root} href="/app/bala/list" title="Bala View">
            {location?.state && (<ViewData objView={objView} />)}
        </Page>
    )
}

export default BalaView;